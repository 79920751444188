    body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background-color: #f4f4f4;
        color: #333;
    }

    h1,
    h2,
    h3 {
        color: #2c3e50;
    }

    h1 {
        font-size: 2em;
        text-align: center;
        margin-top: 20px;
    }

    h2 {
        font-size: 1.5em;
        margin-top: 30px;
        border-bottom: 2px solid #3498db;
        padding-bottom: 5px;
    }

    h3 {
        font-size: 1.2em;
        margin-top: 20px;
    }

    p {
        font-size: 1em;
        line-height: 1.6;
        margin: 15px 0;
        padding: 0 20px;
    }

    ul {
        list-style-type: none;
        padding-left: 20px;
    }

    ul li {
        font-size: 1em;
        margin-bottom: 10px;
    }

    a {
        color: #3498db;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .container {
        max-width: 800px;
        margin: 0 auto;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    footer {
        text-align: center;
        margin-top: 50px;
        font-size: 0.9em;
        color: #777;
    }

    footer a {
        color: #3498db;
    }