.root {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--white);
}

.body {
    display: flex;
    background-color: white;
    flex-direction: column;
    /* background-color: red; */
    flex: 1;
    width: 90%;
    color: var(--surface-100);
    box-shadow: 0 0 10px rgba(150, 148, 153, 0.2);
}

header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--primary-100);
}

header h1 {
    color: var(--surface-600);
}

.rightView {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

nav {
    display: flex;
    align-items: start;
    padding: 15px 0 15px;
    justify-content: space-between;
}

main {
    display: flex;
    flex: 1;
}

/* resposive tasarım için gerekli ekstra css değerleri*/
@media (max-width: 480px) {
    .body {
        width: 100%;
    }

    header h1 {
        font-size: 18px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .body {
        width: 90%;
    }

    header h1 {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .body {
        width: 80%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .body {
        width: 75%;
    }
}

@media (min-width: 1441px) {
    .body {
        width: 70%;
    }
}