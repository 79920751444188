.body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

h1 {
    color: var(--surface-500);
}

.view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 365px;
    width: 90%;
    max-width: 600px;
    min-height: 600px;
    /* background-color: blue; */
}

.img404 {
    width: 100%;
    height: 70%;
}

.button {
    min-width: 365px;
    width: 100%;
}